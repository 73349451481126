.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  text-align: left;
  border: 1px solid black;
  border-radius: 15px;
  margin-bottom: 30px;
  padding: 16px 24px;
  width: 100%;
}
