.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #e4e9ee;
}

.Main {
  display: flex;
  justify-content: center;
}

.Posts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 30%;
}

.Navbar {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #1e6dff;
  color: #eaeaea;
}

.Navbar-action-area {
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: space-between;
  align-items: center;

  .Add-post {
    background: #eaeaea;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: none;
  }
}

.Modal-overlay {
  position: fixed;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -25%);
  width: 30%;
  height: 30%;
  background-color: rgba(255, 255, 255, 0);
}

.Modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;

  .form {
    display: flex;
    flex-direction: column;
  }

  .Input-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
